import * as React from 'react'

import { Box, Container, Grid, Stack, Typography } from '@mui/material'

import type { TypographyProps } from '@mui/material'

const FooterText = ({
  align = 'justify',
  children,
}: TypographyProps) => (
  <Typography
    align={align}
    component='div'
    variant='caption'
    gutterBottom
  >
    {children}
  </Typography>
)

export const Footer = () => (
  <Box
    sx={{
      bgcolor: 'grey.900',
      color: 'grey.400',
    }}
  >
    <Container
      maxWidth='lg'
      sx={{
        display: 'flex',
        pt: 3,
        pb: 2,
      }}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={3}
        >
          <Stack
            spacing={1}
            sx={(theme) => ({
              textAlign: 'center',
              [theme.breakpoints.up('md')]: {
                textAlign: 'left',
              },
            })}
          >
            <Typography>
              felipe@leivaycia.com
              <br />
              +569 6218 2332
            </Typography>
            <Typography>
              tomas@leivaycia.com
              <br />
              +569 8416 8758
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
        >
          <FooterText>
            En Leiva & Carrasco, brindamos soluciones contables y asesoría tributaria para empresas y
            emprendedores en Santiago, Chile. Nos especializamos en optimizar la gestión financiera,
            garantizar el cumplimiento normativo y maximizar la eficiencia fiscal de tu negocio. Con un
            enfoque cercano y profesional, te acompañamos en cada etapa de tu crecimiento, asegurando que
            tomes decisiones estratégicas basadas en información clara y precisa. Confianza, transparencia
            y resultados son los pilares que nos distinguen.
          </FooterText>
          <FooterText align='center'>
            Rosa Rodríguez 1375, Oficina 418, Santiago Centro, Chile.
          </FooterText>
          <FooterText align='center'>
            &copy; Leiva & Carrasco {new Date().getFullYear()}
          </FooterText>
        </Grid>
      </Grid>
    </Container>
  </Box>
)

export default Footer
